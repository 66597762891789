<template>
  <el-card class="page-card">
    <slot name="header" class="clearfix">
      <el-button type="primary" @click="add">
        {{ $t("common.add") }} {{ $t("menu.pricing") }}
      </el-button>
    </slot>
    <sui-list
      ref="list"
      :column="tableColumn"
      :fun="tableFun"
      :req="tableFunReq"
      @edit="edit"
      @del="del"
      @hidden="hidden"
      @backflow="backflow"
    />
    <!-- <el-drawer
      :title="`${$t(formData.id ? 'common.edit' : 'common.add')} ${$t(
        'menu.pricing'
      )}`"
      :visible="editing"
      size="800px"
      close-on-press-escape
      @close="close"
    >
      <sui-form
        ref="form"
        :list="formList"
        :defaultdata="formData"
        @submit="submit"
      />
    </el-drawer> -->
  </el-card>
</template>
<script>
// import SuiForm from "@/components/s-ui/form";
import SuiList from "@/components/s-ui/list";
import {
  // AddLogisticsPrice,
  // EditLogisticsPrice,
  DelLogisticsPrice,
  GetLogisticsPriceList,
} from "@/utils/api";

// const defaultFormData = {
//   isHidden: 0,
//   freightMode: "sea", //  sea海运 air空运
//   fromCountry: "",
//   toCountry: "",
//   price: 1,
//   contactInfo: "",
//   detailAddress: "",
//   way: 101,
//   wayMode: 101,
// };
export default {
  components: {
    // SuiForm,
    SuiList,
  },
  data() {
    return {
      editing: false,
      tableColumn: [
        {
          type: "country",
          name: "fromCountry",
          label: "pricing.fromCountry",
        },
        {
          type: "country",
          name: "toCountry",
          label: "pricing.toCountry",
        },
        {
          type: "freightMode",
          name: "freightMode",
          label: "pricing.freightMode",
          range: [
            {
              label: "pricing.sea",
              value: "sea",
              icon: "icon-haiyun",
            },
            {
              label: "pricing.air",
              value: "air",
              icon: "icon-icon-aviation",
            },
          ].reduce((a, v) => ({ ...a, [v.value]: v.label }), {}),
        },
        {
          type: "price",
          name: "price",
          label: "pricing.price",
        },
        {
          name: "contactInfo",
          label: "pricing.contactInfo",
        },
        {
          name: "detailAddress",
          label: "pricing.detailAddress",
        },
        {
          type: "switch",
          name: "isHidden",
          label: "pricing.isHidden",
        },
      ],
      tableFun: GetLogisticsPriceList,
      tableFunReq: {
        pageSize: 10,
      },
      count: 0,
    };
  },
  mounted() {},
  methods: {
    add() {
      // this.editing = true;
      // const formData = {
      //   type: this.type,
      //   ...defaultFormData,
      // };
      // this.formData = formData;
      // this.$nextTick(() => {
      //   this.formData = formData;
      // });
      this.$router.push({
        path: "/pricing/edit",
      });
    },
    edit(rowData) {
      console.log("edit-rowData:", rowData);
      // this.editing = true;
      this.$router.push({
        path: "/pricing/edit",
        query: {
          id: rowData.id,
        },
      });
      // this.$nextTick(() => {
      //   // this.$refs["form"].setForm(rowData);
      //   this.formData = rowData;
      // });
    },
    del(rowData) {
      console.log("del-rowData:", rowData);
      DelLogisticsPrice({
        id: rowData.id,
      })
        .then((res) => {
          console.log("DelLogisticsPrice-res:", res);
          this.$message({
            type: "success",
            message: this.$t("common.deleteSuccess"),
          });
          this.$refs["list"].getList();
        })
        .catch((err) => {
          console.log("DelLogisticsPrice-err:", err);
        });
    },
    hidden(e, rowData) {
      this.iEditBanner(rowData.id, { isHidden: e }).then(() => {
        this.$message({
          type: "success",
          message: this.$t("common.optSuccess"),
        });
        this.$refs["list"].getList();
      });
    },
    backflow(count) {
      this.count = count;
    },
    // iEditBanner(id, params) {
    //   return EditLogisticsPrice({
    //     id,
    //     ...params,
    //   });
    // },
    // submit(data) {
    //   console.log("submit:", data);
    //   const fn = data.id ? EditLogisticsPrice : AddLogisticsPrice;
    //   // data.fromProvince = data.fromCity[0];
    //   // data.fromCity = data.fromCity[1];
    //   // data.toProvince = data.toCity[0];
    //   // data.toCity = data.toCity[1];
    //   fn(data)
    //     .then((res) => {
    //       console.log("AddLogisticsPrice-res:", res);
    //       this.$message({
    //         type: "success",
    //         message: this.$t("common.createSuccess"),
    //       });
    //       this.editing = false;
    //       this.$refs["list"].getList();
    //     })
    //     .catch((err) => {
    //       console.log("AddLogisticsPrice-err:", err);
    //     });
    // },
    close() {
      this.editing = false;
      this.formData = {};
      this.$refs["form"].resetFields();
    },
  },
};
</script>

<style lang="scss">
.hd {
  &-row {
    color: #333;
    font-weight: normal;
  }
}
.bd {
  &-row {
    text-align: center;
  }
}
page-card {
  .el-icon-upload {
    padding-top: 30px;
  }
}
</style>